const name = 'product';
const icon = '📦';

const labels = {
  genre: 'M',
  singular: {
    fr: 'produit',
    en: 'product'
  },
  plurial: {
    fr: 'produits',
    en: 'products'
  }
};

const customParams = {
  name,
  icon,
  labels,
  fields: {
  },
  actions: [
    'hasPlace',
    'hasProduct',
    'hasClient',
    'hasCreator',
    'hasTechnician',
    'hasCommercial'
  ],
  list: {
    query: {
      inputParams: '($filter: EntityFilter)',
      outputParams: '(filter: $filter)'
    }
  }
};

export { labels, customParams };
