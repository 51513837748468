import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Drawer,
  FormControlLabel,
  Switch,
  TextField,
  Typography
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { setSettingsOpen } from '../redux/slices/appSlice';
import { THEMES } from '../constants';
import useSettings from '../hooks/useSettings';

const getValues = (settings) => ({
  lang: settings.lang,
  compact: settings.compact,
  direction: settings.direction,
  responsiveFontSizes: settings.responsiveFontSizes,
  roundedCorners: settings.roundedCorners,
  theme: settings.theme
});

const SettingsDrawer = () => {
  const { settings, saveSettings } = useSettings();
  const dispatch = useDispatch();

  const { isSettingsOpen } = useSelector((state) => state.app);
  const [values, setValues] = useState(getValues(settings));

  useEffect(() => {
    setValues(getValues(settings));
  }, [settings]);

  const handleClose = () => {
    dispatch(setSettingsOpen(false));
  };

  const handleChange = (field, value) => {
    setValues({
      ...values,
      [field]: value
    });
  };

  const handleSave = () => {
    saveSettings(values);
    dispatch(setSettingsOpen(false));
  };

  return (
    <Drawer
      anchor="right"
      onClose={handleClose}
      open={isSettingsOpen}
      PaperProps={{
        sx: {
          p: 2,
          width: 320
        }
      }}
    >
      <Typography
        color="textPrimary"
        variant="h6"
      >
        Settings
      </Typography>
      <Box sx={{ mt: 3 }}>
        <TextField
          fullWidth
          label="Theme"
          name="theme"
          onChange={(event) => handleChange('theme', event.target.value)}
          select
          SelectProps={{ native: true }}
          value={values.theme}
          variant="outlined"
        >
          {Object.keys(THEMES).map((theme) => (
            <option
              key={theme}
              value={theme}
            >
              {theme
                .split('_')
                .map((w) => w[0].toUpperCase() + w.substr(1).toLowerCase())
                .join(' ')}
            </option>
          ))}
        </TextField>
      </Box>
      <Box sx={{ mt: 3 }}>
        <TextField
          fullWidth
          label="Langue"
          name="lang"
          onChange={(event) => handleChange('lang', event.target.value)}
          select
          SelectProps={{ native: true }}
          value={values.lang || 'fr'}
          variant="outlined"
        >
          {['fr', 'en'].map((lang) => (
            <option
              key={lang}
              value={lang}
            >
              {lang}
            </option>
          ))}
        </TextField>
      </Box>
      <Box
        sx={{
          mt: 2,
          px: 1.5
        }}
      >
        <FormControlLabel
          control={(
            <Switch
              checked={values.direction === 'rtl'}
              color="primary"
              edge="start"
              name="direction"
              onChange={(event) => handleChange('direction', event.target.checked
                ? 'rtl'
                : 'ltr')}
            />
            )}
          label={(
            <div>
              RTL
              <Typography
                color="textSecondary"
                component="p"
                variant="caption"
              >
                Change text direction
              </Typography>
            </div>
            )}
        />
      </Box>
      <Box
        sx={{
          mt: 2,
          px: 1.5
        }}
      >
        <FormControlLabel
          control={(
            <Switch
              checked={values.responsiveFontSizes}
              color="primary"
              edge="start"
              name="direction"
              onChange={(event) => handleChange('responsiveFontSizes', event.target.checked)}
            />
            )}
          label={(
            <div>
              Responsive font sizes
              <Typography
                color="textSecondary"
                component="p"
                variant="caption"
              >
                Adjust font for small devices
              </Typography>
            </div>
            )}
        />
      </Box>
      <Box
        sx={{
          mt: 2,
          px: 1.5
        }}
      >
        <FormControlLabel
          control={(
            <Switch
              checked={values.compact}
              color="primary"
              edge="start"
              name="compact"
              onChange={(event) => handleChange('compact', event.target.checked)}
            />
            )}
          label={(
            <div>
              Compact
              <Typography
                color="textSecondary"
                component="p"
                variant="caption"
              >
                Fixed width on some screens
              </Typography>
            </div>
            )}
        />
      </Box>
      <Box
        sx={{
          mt: 2,
          px: 1.5
        }}
      >
        <FormControlLabel
          control={(
            <Switch
              checked={values.roundedCorners}
              color="primary"
              edge="start"
              name="roundedCorners"
              onChange={(event) => handleChange('roundedCorners', event.target.checked)}
            />
            )}
          label={(
            <div>
              Rounded Corners
              <Typography
                color="textSecondary"
                component="p"
                variant="caption"
              >
                Increase border radius
              </Typography>
            </div>
            )}
        />
      </Box>
      <Box sx={{ mt: 3 }}>
        <Button
          color="primary"
          fullWidth
          onClick={handleSave}
          variant="contained"
        >
          Save Settings
        </Button>
      </Box>
    </Drawer>
  );
};

export default SettingsDrawer;
