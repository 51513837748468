import { isArray, mergeWith } from 'lodash';
import * as entityList from '..';

const baseEntity = {
  name: 'entity',
  labels: {
    genre: 'M',
    singular: {},
    plurial: {}
  },
  fields: {
    id: {},
    typeOf: {
      required: true
    },
    name: {
      required: true
    },
    description: {},
    password: {},
    shareStatus: {},
    commentStatus: {},
    guid: {},
    lang: {},
    status: {
      required: true,
      defaultValue: '0'
    },
    createdAt: {},
    updatedAt: {}
  },
  actions: [
    'hasUrl',
    'hasParent'
  ],
  analytics: {
    totalPower: {}
  },

  /*
   * Single affiche un élément
   * - envoi de la requête à la bdd
   * - affichage des éléments dans le cadre détails
   */
  single: {
    query: {
      type: 'query',
      inputName: 'entity',
      inputParams: '($id: ID!)',
      outputName: 'entity',
      outputParams: '(id: $id)',
      outputFields: [
        'id',
        'guid',
        'name',
        'typeOf',
        'status',
        'createdAt',
        'updatedAt',
        'lang',
        'entityFields {id name value}',
        'defaultActions {id target { id typeOf name } agent { id typeOf name } property {guid}}'
      ]
    }
  },

  /*
   * Details
   */
  details: {
    query: {
      type: 'query',
      inputName: 'project',
      inputParams: '($id: ID!)',
      outputName: 'project',
      outputParams: '(id: $id)',
      outputFields: [
        'id',
        'name',
        'ref',
        'typeEmergency'
      ]
    },
    sideBar: [
      'createdAt',
      'updatedAt'
    ],
    tabs: {
      roof: {
        type: 'query',
        inputName: 'project',
        inputParams: '($id: ID!)',
        outputName: 'project',
        outputParams: '(id: $id)',
        outputFields: [
          'roofs {id name}'
        ],
        getResults: (response) => response.roofs
      }
    }
  },

  /*
   * List affiche l'ensemble des éléments
   * - envoi de la requête à la bdd
   * - affichage des éléments dans le tableau
   * - génère les colonnes du tableau
   * - Génère les tabs pour filtres
   * - Génère la fonction de recherche
   * - Génère la logique de tri
   */
  list: {
    query: {
      type: 'query',
      inputName: 'allEntities',
      inputParams: '($filter: EntityFilter)',
      outputName: 'allEntities',
      outputParams: '(filter: $filter)',
      outputFields: [
        'id',
        'guid',
        'name',
        'status',
        'typeOf',
        'createdAt',
        'updatedAt',
        'lang'
      ]
    },
    table: {
      searchFunc: (option) => option.name,
      sortModel: [
        {
          field: 'name',
          sort: 'desc'
        }
      ]
    },
    tabs: [
      {
        headerName: 'Tous',
        field: '',
        value: 'all'
      },
      {
        headerName: 'En Cours',
        field: 'status',
        value: 'en cours'
      },
      {
        headerName: 'Complete',
        field: 'status',
        value: 'complete'
      },
      {
        headerName: 'Archivés',
        field: 'status',
        value: 'archive'
      }
    ]
  },

  /*
   * Create
   * - créé le formulaire
   * - envoie la requête à la bdd
   * - récupère l'id du nouvel élément
   * - renvoie vers la page de l'élément.
   */
  create: {
    query: {
      type: 'mutation',
      inputName: 'createEntity',
      inputParams: '($input: CreateEntityInput!)',
      outputName: 'createEntity',
      outputParams: '(input: $input)',
      outputFields: [
        'id',
        'name',
        'typeOf'
      ]
    }
  },

  /*
   * Update
   */
  update: {
    query: {
      type: 'mutation',
      inputName: 'createEntity',
      inputParams: '($input: CreateEntityInput!)',
      inputFields: [
        'name',
        'dateDelivery',
        'dateReception',
        'typeEmergency',
        'status'
      ],
      outputName: 'createEntity',
      outputParams: '(input: $input)',
      outputFields: [
        'id',
        'name'
      ]
    }
  },

  /*
   * Remove
   */
  remove: {
    query: {
      type: 'mutation',
      inputName: 'destroyEntity',
      inputParams: '($input: DestroyEntityInput!)',
      outputName: 'destroyEntity',
      outputParams: '(input: $input)',
      outputFields: [
        'id',
        'name'
      ]
    }
  },

  /*
   * OnSubmit
   */
  onSubmit: {
    type: 'redirect',
    route: '/datas/:entityName/:entityId'
  }
};

function customizer(objValue, srcValue) {
  if (isArray(objValue)) {
    return objValue.concat(srcValue);
  }
  return undefined;
}

const entities = Object
  .keys(entityList)
  .reduce((accumulator, entityName) => ({
    ...accumulator,
    [entityName]: mergeWith({}, baseEntity, (entityList[entityName] || {}), customizer)
  }), {});

const labels = Object
  .keys(entityList)
  .reduce((accumulator, entityName) => ({
    ...accumulator,
    [entityName]: entityList[entityName].labels
  }),
  {});

export default entities;
export { baseEntity, entities, labels };
