import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Drawer, experimentalStyled, SvgIcon } from '@material-ui/core';
import ReceiptIcon from '@material-ui/icons/Receipt';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import HomeIcon from '@material-ui/icons/Home';
import SettingsSharpIcon from '@material-ui/icons/SettingsSharp';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import useAuth from '../../hooks/useAuth';
import BriefcaseIcon from '../../assets/icons/Briefcase';
import ChartSquareBarIcon from '../../assets/icons/ChartSquareBar';
import ChevronUpIcon from '../../assets/icons/ChevronUp';
import StarIcon from '../../assets/icons/Star';
import UserIcon from '../../assets/icons/User';
import UsersIcon from '../../assets/icons/Users';
import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';
import { ReactComponent as LogoDark } from '../../assets/logo/logo-dark.svg';

const sections = [
  {
    title: 'General',
    items: [
      {
        title: 'Projects',
        path: '/datas/project',
        icon: <BriefcaseIcon fontSize="medium" />
      },
      {
        title: 'Clients',
        path: '/datas/client',
        icon: <UserIcon fontSize="medium" />
      },
      {
        title: 'Entreprises',
        path: '/datas/company',
        icon: <StarIcon fontSize="medium" />
      },
      {
        title: 'Toitures',
        path: '/datas/roof',
        icon: <HomeIcon fontSize="medium" />
      },
      {
        title: 'Contacts',
        path: '/datas/person',
        icon: <ContactPhoneIcon fontSize="medium" />
      },
      {
        title: 'Produits',
        path: '/datas/product',
        icon: <ChevronUpIcon fontSize="medium" />
      },
      {
        title: 'Places',
        path: '/datas/place',
        icon: <LocationOnIcon fontSize="medium" />
      }
    ]
  },
  {
    title: 'Admin',
    items: [
      {
        title: 'Utilisateurs',
        path: '/datas/user',
        icon: <UsersIcon fontSize="medium" />
      },
      {
        title: 'Régles',
        path: '/datas/rule',
        icon: <ChartSquareBarIcon fontSize="medium" />
      },
      {
        title: 'Rapports',
        path: '/datas/report',
        icon: <ReceiptIcon fontSize="medium" />
      },
      {
        title: 'App Settings',
        path: '/settings',
        icon: <SettingsSharpIcon fontSize="medium" />
      }
    ]
  }
];

const DashboardSidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { user } = useAuth();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname, user]);

  const Logo = experimentalStyled(SvgIcon)(({ theme }) => ({
    fill: theme.palette.mode === 'dark'
      ? theme.palette.grey[800]
      : theme.palette.grey[100],
    width: '100%'
  }));

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            pt: 3,
            px: 2
          }}
        >
          <RouterLink to="/">
            <Logo
              component={LogoDark}
              viewBox="0 0 96 26"
            />
          </RouterLink>
        </Box>
        <Box sx={{ p: 1 }}>
          {sections.map((section) => {
            // Roles
            if (user.position !== 'admin') {
              // return null;
            }
            return (
              <NavSection
                key={section.title}
                pathname={location.pathname}
                sx={{
                  '& + &': {
                    mt: 2
                  }
                }}
                {...section}
              />
            );
          })}
        </Box>
      </Scrollbar>
    </Box>
  );

  return (
    <>
      <Drawer
        anchor="left"
        onClose={onMobileClose}
        open={openMobile}
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            width: 220
          }
        }}
        variant="temporary"
      >
        {content}
      </Drawer>
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'primary.main',
            width: 72,
            borderRadius: 2,
            border: 0,
            top: 48,
            left: 8,
            height: 'calc(100% - 48px - 12px)'
          }
        }}
        variant="persistent"
      >
        {content}
      </Drawer>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default DashboardSidebar;
