const name = 'person';
const icon = '😊';

const labels = {
  genre: 'M',
  singular: {
    fr: 'contact',
    en: 'contact'
  },
  plurial: {
    fr: 'contacts',
    en: 'contacts'
  }
};

const customParams = {
  name,
  icon,
  labels,
  fields: {
    firstName: {},
    lastName: {},
    email: {},
    phone: {},
    jobPosition: {}
  },
  actions: [
    'hasPlace',
    'hasProduct',
    'hasClient',
    'hasCreator',
    'hasTechnician',
    'hasCommercial'
  ],
  list: {
    query: {
      inputParams: '($filter: EntityFilter)',
      outputParams: '(filter: $filter)'
    }
  }
};

export { labels, customParams };
