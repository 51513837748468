import { Suspense, lazy } from 'react';
import AuthGuard from '../components/AuthGuard';
import DashboardLayout from '../components/dashboard/DashboardLayout';
import GuestGuard from '../components/GuestGuard';
import LoadingScreen from '../components/LoadingScreen';
import MainLayout from '../components/MainLayout';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Authentication pages
const Login = Loadable(lazy(() => import('../views/authentication/Login')));
const Logout = Loadable(lazy(() => import('../views/authentication/Logout')));
const PasswordRecovery = Loadable(lazy(() => import('../views/authentication/PasswordRecovery')));
const PasswordReset = Loadable(lazy(() => import('../views/authentication/PasswordReset')));
const Register = Loadable(lazy(() => import('../views/authentication/Register')));
const VerifyCode = Loadable(lazy(() => import('../views/authentication/VerifyCode')));

/*
 * Dashboard pages
 */

// -- Entity
const EntityCreate = Loadable(lazy(() => import('../views/entity/EntityCreateSingle')));
const EntityList = Loadable(lazy(() => import('../views/entity/EntityListSingle')));
const EntitySingle = Loadable(lazy(() => import('../views/entity/EntitySingle')));

// -- Import File
const ImportFile = Loadable(lazy(() => import('../views/dashboard/ImportFile')));

// Theme
const Account = Loadable(lazy(() => import('../views/dashboard/Account')));
const Overview = Loadable(lazy(() => import('../views/dashboard/Overview')));
const AppSettings = Loadable(lazy(() => import('../views/dashboard/AppSettings')));

// Error pages
const AuthorizationRequired = Loadable(lazy(() => import('../views/pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('../views/pages/NotFound')));
const ServerError = Loadable(lazy(() => import('../views/pages/ServerError')));

const routes = [
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: 'login-unguarded',
        element: <Login />
      },
      {
        path: 'logout',
        element: <Logout />
      },
      {
        path: 'password-recovery',
        element: <PasswordRecovery />
      },
      {
        path: 'password-reset',
        element: <PasswordReset />
      },
      {
        path: 'register',
        element: (
          <GuestGuard>
            <Register />
          </GuestGuard>
        )
      },
      {
        path: 'register-unguarded',
        element: <Register />
      },
      {
        path: 'verify-code',
        element: <VerifyCode />
      }
    ]
  },
  {
    path: '/',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: <Overview />
      },
      {
        path: '/import',
        element: <ImportFile />
      },
      {
        path: '/settings',
        element: <AppSettings />
      },
      {
        /*
         *  Entity
         */
        path: 'datas',
        children: [
          {
            path: '/:entityName',
            element: <EntityList />
          },
          {
            path: '/:entityName/create',
            element: <EntityCreate />
          },
          {
            path: ':entityName/:id',
            element: <EntitySingle />
          }
        ]
      },

      /*
       *  Account
       */
      {
        path: 'account',
        element: <Account />
      }
    ]
  },
  {
    path: '*',
    element: <MainLayout />,
    children: [
      {
        path: '401',
        element: <AuthorizationRequired />
      },
      {
        path: '404',
        element: <NotFound />
      },
      {
        path: '500',
        element: <ServerError />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
];

export default routes;
