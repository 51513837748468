const name = 'report';
const icon = '📊';

const labels = {
  genre: 'M',
  singular: {
    fr: 'rapport',
    en: 'report'
  },
  plurial: {
    fr: 'rapports',
    en: 'reports'
  }
};

const customParams = {
  name,
  icon,
  labels,
  fields: {
  },
  actions: [
    'hasPlace',
    'hasProduct',
    'hasClient',
    'hasCreator',
    'hasTechnician',
    'hasCommercial'
  ]
};

export { labels, customParams };
