import { experimentalStyled } from '@material-ui/core/styles';

const LogoRoot = experimentalStyled('svg')({});

const Logo = (props) => (
  <LogoRoot
    height="70"
    version="1.1"
    viewBox="0 0 100 70"
    width="100"
    {...props}
  >
    <g
      clipPath="url(#prefix__clip0)"
      filter="url(#prefix__filter0_d)"
    >
      <path
        d="M11.743 23.917H4.514L2 26.997h11.437s5.22.478 5.22 5.123c0 4.46-3.607 5.821-5.854 5.821H5.259v-9.875H2v12.76h9.743c2.015 0 3.483-.223 4.743-.666a8.766 8.766 0 002.954-1.816c.912-.774 1.424-1.691 1.946-2.757.522-1.065.629-2.216.629-3.457a7.516 7.516 0 00-.764-3.382 7.92 7.92 0 00-2.128-2.662 9.884 9.884 0 00-3.253-1.726c-1.26-.408-2.637-.443-4.127-.443zM71.809 23.694c-1.339 0-2.178 1.616-2.178 1.616l-6.252 11.129-6.232-11.089s-.758-1.65-2.03-1.65c-.39-.03-.777.09-1.078.34a3.064 3.064 0 00-1.085 1.776l-3.97 15.01h3.54L55.337 29.1l6.125 10.793s.597 1.106 1.675 1.213a4.129 4.129 0 00.83-.07 1.96 1.96 0 00.71-.27c.343-.368.639-.777.88-1.219.362-.679 5.84-10.453 5.84-10.453l2.744 11.726h3.605l-4.001-15.004s-.597-2.122-1.936-2.122M96.701 24.005H80.546v16.822h13.905L97 37.89H83.922v-4.067h9.668l2.414-3.04H83.922v-3.8h10.45l2.33-2.979zM96.24 47.42l-1.393-.883c.597-.141.943-.48.943-.943 0-.628-.629-1.053-1.685-1.053h-3.312v2.895h.801V45.03h2.514c.557 0 .887.185.887.56 0 .373-.315.58-.887.58h-1.904v.447h1.732l1.175.804h1.128zm-5.944 0l-2.672-2.772a.493.493 0 00-.386-.163.51.51 0 00-.396.163l-2.665 2.772h.924l2.102-2.222 1.034 1.085h-1.42l-.384.408h2.2l.698.73h.965zm-6.387 0v-.506h-3.117v-2.388h-.808v2.894h3.925zm-7.98-.506c-.836 0-1.44-.402-1.44-.942 0-.541.63-.943 1.44-.943h1.129c.855 0 1.461.393 1.461.943 0 .55-.606.942-1.461.942h-1.129zm1.129.506c1.32 0 2.269-.628 2.269-1.477 0-.848-.943-1.417-2.27-1.417H75.93c-1.291 0-2.247.562-2.247 1.417s.943 1.477 2.247 1.477h1.129zm-5.378 0c.968 0 1.44-.364 1.44-.848 0-.563-.503-.836-1.44-.836h-2.125c-.355 0-.578-.132-.578-.352 0-.22.217-.355.585-.355h3.328v-.503h-3.316c-.924 0-1.427.345-1.427.81 0 .466.544.824 1.418.824h2.128c.433 0 .675.129.675.374s-.242.38-.675.38h-3.458v.506h3.445z"
        fill="#fff"
      />
      <path
        d="M35.944 42.222c5.584 0 10.111-4.527 10.111-10.111 0-5.584-4.527-10.111-10.11-10.111-5.585 0-10.112 4.527-10.112 10.111 0 5.584 4.527 10.111 10.111 10.111z"
        fill="url(#prefix__paint0_radial)"
      />
      <path
        opacity={0.15}
        d="M33.273 33.368a3.929 3.929 0 100-7.857 3.929 3.929 0 000 7.857z"
        fill="#fff"
      />
      <path
        opacity={0.35}
        d="M35.454 27.029a4.4 4.4 0 00-2.565.044c-.588.194-1.133.499-1.606.899a3.229 3.229 0 01-.27.204c-.24-.04-.483-.06-.726-.06-.037-.272.03-.548.189-.773a2.957 2.957 0 011.8-1.22 3.369 3.369 0 013.178.906z"
        fill="#fff"
      />
      <path
        d="M36.573 40.748a6.538 6.538 0 01-2.417-.085c-2.863-.647-4.894-3.105-5.371-6.091a9.134 9.134 0 003.344 4.598 8.21 8.21 0 004.444 1.578z"
        fill="#fff"
      />
      <path
        opacity={0.4}
        d="M43.044 27.818A6.509 6.509 0 0144.4 29.82c1.166 2.693.377 5.783-1.75 7.926a9.25 9.25 0 001.87-5.409 8.26 8.26 0 00-1.475-4.52z"
        fill="#fff"
      />
    </g>
    <defs>
      <radialGradient
        id="prefix__paint0_radial"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(38.144 35.515) scale(16.3247)"
      >
        <stop stopColor="#fff" />
        <stop
          offset={0.53}
          stopColor="#FFC20E"
        />
        <stop
          offset={0.84}
          stopColor="#DF7636"
        />
      </radialGradient>
      <clipPath id="prefix__clip0">
        <path
          fill="#fff"
          transform="translate(2 22)"
          d="M0 0h95v25.421H0z"
        />
      </clipPath>
      <filter
        id="prefix__filter0_d"
        x={0}
        y={21}
        width={99}
        height={29.421}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood
          floodOpacity={0}
          result="BackgroundImageFix"
        />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={1} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
    </defs>
  </LogoRoot>
);

export default Logo;
