import { createSlice } from '@reduxjs/toolkit';
import { merge } from 'lodash';
import toast from 'react-hot-toast';
import { entities } from '../../entity/utils/entities';
import fetchGQL from '../../lib/fetchGQL';

const initialState = {
  current: {},
  allIds: [],
  byType: {},
  byId: {}
};

const slice = createSlice({
  name: 'entity',
  initialState,
  reducers: {
    // Queries
    getOne(state, action) {
      const { entity } = action.payload.data;
      const existingEntries = state.byType[entity.typeOf];
      const existingEntry = existingEntries
        ? existingEntries[entity.id]
        : {};

      const newEntity = merge((existingEntry || {}), entity);
      state.byType[entity.typeOf] = (state.byType[entity.typeOf] || []);
      state.byType[entity.typeOf][entity.id] = newEntity;
      state.byId[entity.id] = newEntity;

      if (entity.id && !state.allIds.includes(entity.id.toString())) {
        state.allIds.push(entity.id.toString());
      }
    },
    getAll(state, action) {
      const { typeOf } = action.payload;
      const { allEntities } = action.payload.data;
      const outputEntries = state.byType[typeOf] ?? {};

      if (allEntities && allEntities.length) {
        allEntities.forEach((entity) => outputEntries[entity.id.toString()] = entity);

        state.allIds = [...state.allIds, ...Object.keys(outputEntries)];
        state.byType[typeOf] = outputEntries;
      }
    },
    // Mutations
    create(state, action) {
      const entity = action.payload.data.createEntity;
      const { id, typeOf } = entity;
      const outputEntries = state.byType[typeOf] ?? {};
      outputEntries[id] = entity;
      state.allIds.push(id.toString());
      state.byType[typeOf] = outputEntries;
    },
    update(state, action) {
      const entity = action.payload[action];
      const entityId = entity.id;
      state.byId = { ...state.byId, [entityId]: entity };
      return { ...entity };
    },
    remove(state, action) {
      const { id, typeOf } = action.payload;
      delete state.byType[typeOf][id];
      state.allIds = state.allIds.filter((entityId) => entityId.toString() !== id.toString());
    }
  }
});

const createQuery = (query) => {
  const {
    type = 'query',
    inputName = '',
    inputParams = '',
    outputName = '',
    outputParams = '',
    outputFields = []
  } = query;

  return `${type} ${inputName}${inputParams}{
      ${outputName}${outputParams} {
        ${outputFields?.join(' ')}
      }
    }`;
};

const makeFetchFunc = (action, queryName) => (inputs = null, successMessage = null) => async (dispatch) => {
  const { typeOf = 'project', id = null } = inputs;
  try {
    if (!typeOf) throw new Error('Problème dans la requête : typeOf missing');
    const entity = entities[typeOf];
    const queryString = createQuery(entity[queryName].query);
    const response = await fetchGQL(queryString, inputs);
    if (!response.data) {
      console.error(response.error);
      toast.error('Oupsy - une erreur est survenue dans la réponse');
    }
    if (successMessage !== null) toast.success(successMessage);
    dispatch(action({
      id,
      typeOf,
      data: { ...response.data }
    }));
    return response.data;
  } catch (err) {
    console.error(err);
    toast.error('Oupsy - une erreur est survenue pendant l\'action');
  }
  return false;
};

export const { reducer } = slice;

export const getAll = makeFetchFunc(slice.actions.getAll, 'list');
export const getOne = makeFetchFunc(slice.actions.getOne, 'single');
export const create = makeFetchFunc(slice.actions.create, 'create');
export const update = makeFetchFunc(slice.actions.update, 'update');
export const remove = makeFetchFunc(slice.actions.remove, 'remove');

export default slice;
