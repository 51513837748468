const name = 'roof';
const icon = '🏠';

const labels = {
  genre: 'M',
  singular: {
    fr: 'toiture',
    en: 'roof'
  },
  plurial: {
    fr: 'toitures',
    en: 'roofs'
  }
};

const customParams = {
  name,
  icon,
  labels,
  fields: {
  },
  actions: [
    'hasPlace',
    'hasProduct',
    'hasClient',
    'hasCreator',
    'hasTechnician',
    'hasCommercial'
  ],
  list: {
    query: {
      inputParams: '($filter: EntityFilter)',
      outputParams: '(filter: $filter)'
    }
  }
};

export { labels, customParams };
