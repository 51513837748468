const name = 'company';
const icon = '🚀';

const labels = {
  genre: 'M',
  singular: {
    fr: 'entreprise',
    en: 'company'
  },
  plurial: {
    fr: 'entreprises',
    en: 'companies'
  }
};

const customParams = {
  name,
  icon,
  labels,
  fields: {
  },
  actions: [
    'hasPlace',
    'hasProduct',
    'hasClient',
    'hasCreator',
    'hasTechnician',
    'hasCommercial'
  ],
  list: {
    query: {
      inputParams: '($filter: EntityFilter)',
      outputParams: '(filter: $filter)'
    }
  }
};

export { labels, customParams };
