const name = 'place';
const icon = '🚩';

const labels = {
  genre: 'M',
  singular: {
    fr: 'lieu',
    en: 'place'
  },
  plurial: {
    fr: 'lieux',
    en: 'places'
  }
};

const customParams = {
  name,
  icon,
  labels,
  fields: {
    altitude: {
      defaultValue: 500
    },
    areaSea: {},
    areaField: {},
    areaSnow: {},
    areaWind: {},
    gps: {},
    address: {}
  },
  actions: [],
  list: {
    query: {
      inputParams: '($filter: EntityFilter)',
      outputParams: '(filter: $filter)'
    }
  }
};

export { labels, customParams };
