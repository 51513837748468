const name = 'project';
const icon = '🔥';

const labels = {
  genre: 'M',
  singular: {
    fr: 'projet',
    en: 'project'
  },
  plurial: {
    fr: 'projets',
    en: 'projects'
  }
};

const customParams = {
  name,
  icon,
  labels,
  fields: {
    typeEmergency: {
      defaultValue: false
    },
    dateReception: {
      width: {
        xl: '50%'
      }
    },
    dateDelivery: {
      width: {
        xl: '50%'
      }
    }
  },
  actions: [
    'hasClient',
    'hasCreator',
    'hasTechnician',
    'hasCommercial',
    'hasProduct',
    'hasSolarRoof',
    'isLocated'
  ]
};

export { labels, customParams };
