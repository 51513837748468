const name = 'client';
const icon = '🤑';

const labels = {
  genre: 'M',
  singular: {
    fr: 'client',
    en: 'client'
  },
  plurial: {
    fr: 'clients',
    en: 'clients'
  }
};

const customParams = {
  name,
  icon,
  labels,
  fields: {
  },
  actions: [
    'isOwnBy'
  ]
};

export { labels, customParams };
