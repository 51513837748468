import AsyncLocalStorage from '@createnextapp/async-local-storage';
import axiosInstance from './axios';

let token = null;

const fetchGQL = async (query, variables = null) => {
  let response = null;
  token = !token ? await AsyncLocalStorage.getItem('accessToken') : token;

  let outputVariables = {};

  // ID type is Int! and not String
  if (variables && ('id' in variables)) {
    outputVariables.id = variables.id.toString();
  }

  outputVariables.filter = {
    ...variables
  };

  // In case of a mutation, input object necessary
  if (query.includes('mutation')) {
    if (query.includes('destroy')) {
      outputVariables = {
        input: {
          id: parseInt(variables.id, 10)
        }
      };
    } else {
      outputVariables = {
        input: {
          ...variables
        }
      };
    }
  }

  try {
    response = await axiosInstance.post('/graphql', {
      query,
      variables: outputVariables
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });

    // Checker ici les erreurs GQL

    return JSON.parse(JSON.stringify(response.data));
  } catch (err) {
    console.error('fetchGQL NETWORK', err);
    return response.data.errors;
  }
};

export default fetchGQL;
