import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  isSettingsOpen: false
};

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = !!action.payload;
    },
    setIsSettingsOpen(state, action) {
      state.isSettingsOpen = !!action.payload;
    }
  }
});

export const { reducer } = slice;

export const setAppLoading = (status) => (dispatch) => {
  dispatch(slice.actions.setIsLoading(status));
};

export const setSettingsOpen = (status) => (dispatch) => {
  dispatch(slice.actions.setIsSettingsOpen(status));
};

export default slice;
