import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Drawer, IconButton, Stack, SvgIcon, Tab, Typography } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import { experimentalStyled } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import useAuth from '../../hooks/useAuth';
import AdjustmentsIcon from '../../assets/icons/Adjustments';

import { ReactComponent as LogoDots } from '../../assets/logo/logo-dots-icon.svg';
import { setSettingsOpen } from '../../redux/slices/appSlice';

const Logo = experimentalStyled(SvgIcon)({});

const StyledTabs = experimentalStyled((props) => (
  <Tabs
    {...props}
  />
))(() => ({
  '& .MuiTabs-scroller': {
    height: 35
  },
  '& .MuiTabs-indicator': {
    backgroundColor: 'text.primary'
  }
}));

const StyledTab = experimentalStyled((props) => (
  <Tab
    disableRipple
    {...props}
  />
))(
  ({ theme }) => ({
    textTransform: 'none',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 'normal',
    minHeight: 35,
    minWidth: '70px!important',
    height: 35,
    color: 'text.primary',
    '&.Mui-selected': {
      color: 'text.primary'
    }
  })
);

const DashboardTopBar = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const dispatch = useDispatch();
  const { user } = useAuth();

  const [value, setValue] = useState('connect');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleOpen = () => {
    dispatch(setSettingsOpen(true));
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname, user]);

  const content = (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      pr={3}
    >
      <Box
        display="flex"
        height={35}
      >
        <Box
          pt={0.6}
          px={1.5}
          mr={1}
          borderRadius="0 20px 20px 0"
          borderRight={1}
          borderColor="divider"
        >
          <Logo
            component={LogoDots}
            viewBox="0 0 68 47"
          />
        </Box>
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label="wrapped label tabs example"
        >
          <StyledTab
            value="connect"
            label="Vos données"
          />
          <StyledTab
            value="save"
            label="Vos bookmarks"
          />
          <StyledTab
            value="page"
            label="Découvrir"
          />
          <StyledTab
            value="discover"
            label="Store"
          />
        </StyledTabs>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignContent: 'flex-end',
          alignItems: 'center',
          fontSize: 12
        }}
      >
        <Typography
          variant="body2"
          mr={1}
        >
          Lang
        </Typography>
        <IconButton
          onClick={handleOpen}
          size="small"
          aria-label="close"
        >
          <AdjustmentsIcon fontSize="small" />
        </IconButton>
      </Box>
    </Stack>
  );

  return (
    <Drawer
      anchor="top"
      open
      PaperProps={{
        sx: {
          backgroundColor: 'background.default',
          width: '100%',
          borderRadius: 0,
          top: 0,
          left: 0,
          height: 35,
          overflow: 'hidden',
          borderBottom: 1,
          borderColor: 'divider'
        }
      }}
      variant="persistent"
    >
      {content}
    </Drawer>
  );
};

DashboardTopBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default DashboardTopBar;
