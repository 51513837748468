import { useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Button, Collapse, ListItem, Tooltip } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';
import ChevronDownIcon from '../assets/icons/ChevronDown';
import ChevronRightIcon from '../assets/icons/ChevronRight';
import roundCorner from '../assets/img/decoration/round-corner.svg';

const NavItem = (props) => {
  const { active, children, depth, icon, info, open: openProp, path, title, ...other } = props;
  const [open, setOpen] = useState(openProp);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  let paddingLeft = 16;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  // Branch
  if (children) {
    return (
      <ListItem
        disableGutters
        sx={{
          display: 'block',
          py: 0
        }}
        {...other}
      >
        <Button
          endIcon={!open ? <ChevronRightIcon fontSize="small" />
            : <ChevronDownIcon fontSize="small" />}
          onClick={handleToggle}
          startIcon={icon}
          sx={{
            color: 'text.dark',
            fontWeight: 'fontWeightMedium',
            justifyContent: 'flex-start',
            pl: `${paddingLeft}px`,
            pr: '8px',
            py: '12px',
            textAlign: 'left',
            textTransform: 'none',
            width: '100%'
          }}
          variant="text"
        >
          <Box sx={{ flexGrow: 1 }}>
            {title}
          </Box>
          {info}
        </Button>
        <Collapse in={open}>
          {children}
        </Collapse>
      </ListItem>
    );
  }

  // Leaf
  return (
    <ListItem
      button
      disableGutters
      sx={{
        py: 0
      }}
    >
      <Tooltip
        title={title}
        TransitionComponent={Zoom}
        placement="right"
        arrow
      >
        <Button
          component={path && RouterLink}
          sx={{
            color: 'white',
            fontWeight: 'fontWeightRegular',
            justifyContent: 'flex-start',
            textAlign: 'left',
            mx: 'auto',
            mb: 1,
            p: 1,
            textTransform: 'none',
            minWidth: 'inherit',
            borderRadius: 40,
            '&:hover': {
              color: 'black',
              backgroundColor: 'white'
            },
            ...(active && {
              color: 'black',
              backgroundColor: 'white',
              marginRight: -1,
              borderRight: '15px solid white',
              borderRadius: '30px 0 0 30px',
              fontWeight: 'fontWeightBold',
              position: 'relative',
              '&:before': {
                content: `url(${roundCorner})`,
                display: 'block',
                width: 20,
                height: 20,
                right: -15,
                position: 'absolute',
                bottom: '100%'
              },
              '&:after': {
                content: `url(${roundCorner})`,
                display: 'block',
                width: 20,
                height: 20,
                right: -15,
                position: 'absolute',
                top: '100%',
                transform: 'scaleY(-1)'
              }
              // '& svg': {
              //   color: 'primary.main'
              // }
            })
          }}
          variant="text"
          to={path}
        >
          {icon}
        </Button>
      </Tooltip>
    </ListItem>
  );
};

NavItem.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  depth: PropTypes.number.isRequired,
  icon: PropTypes.node,
  info: PropTypes.node,
  open: PropTypes.bool,
  path: PropTypes.string,
  title: PropTypes.string.isRequired
};

NavItem.defaultProps = {
  active: false,
  open: false
};

export default NavItem;
