import { combineReducers } from '@reduxjs/toolkit';
import { reducer as EntityReducer } from './slices/entitySlice';
import { reducer as AppReducer } from './slices/appSlice';

const rootReducer = combineReducers({
  entity: EntityReducer,
  app: AppReducer
});

export default rootReducer;
